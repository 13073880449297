export const DOMAIN = 'https://www.up4hire.com/JM-JOBPORTAL-BACK/public/api'
export const ATSDOMAIN = "https://www.up4hire.com/JM-ATS/public/images/jobportal_homepage/"
export const ATS_URL = "https://www.up4hire.com/JM-ATS/public";
export const PAGINATION_COUNT=10;
export const LINKEDIN_CLIENT_ID="86bpknetd563mv";
export const COMPANY_MAP_LOC ="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2886.4382004055337!2d-79.6064477345021!3d43.659855579120986!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b38554ebfffff%3A0x4207b65426702cf!2s5225%20Orbitor%20Dr%20%2322%2C%20Mississauga%2C%20ON%20L4W%204Y8%2C%20Canada!5e0!3m2!1sen!2sin!4v1620457063664!5m2!1sen!2sin";
export const EMAIL_DOMAIN_LIST = "@gmail.com,@yaahoo.com,@hotmail.com,@rediffmail.com,@Test.com";
export const FB_APP_ID=839539459976551;
export const GOOGLE_CLIENT_ID="117934372606-n5in7uet81o9o1n8urvmhasodqjscjpr.apps.googleusercontent.com";
export const GOOGLE_MAP_API_KEY="AIzaSyCeZuxuZ2DMiZQNR6fpSJ57MtMEkq0UyaM";
//home
export const GETHOMEPAGE = "/getHomePage";
export const UPLOAD_REGISTER = "/uploadRegister";
export const REGISTER_JOBSEEKERS = "/registerJobseekers";
export const UPDATE_JOBSEEKERS_OFFICIAL = "/updateSeekersOfficial";
//JOBDETAILS
export const APPLY_JOB = "/applyJob";
export const FAVOURITE_JOB = "/favouriteJob";
export const GET_JOB_DETAIL = "/getJobDetail";
export const JOB_APPLY_FAVOURITE = "/jobapply_favourite";
//JOBLISTING
export const GET_JOB_LIST = "/getJoblist";
export const GET_JOB_LIST_PAGE = "/getJoblist?page=";
export const SORT_ALERT = "/storeAlert";
export const LINKED_IN = "/linkedIn";
export const CHECK_RESUME = "/checkResume";

//LOGIN
export const FORGOT_PASSWORD = "/forgotPassword";
export const LOGIN = "/login";
export const ACTIVATE_ACCOUNT = "/activateaccount";
export const ACTIVATE = "/activate";
//MYJOBS
export const GET_MY_JOBLIST = "/getMyJoblist";
export const GET_MY_JOBLIST_PAGE = "/getMyJoblist?page=";
//PROFILE
export const UPLOAD_MY_RESUME = '/uploadMyResume';
export const UPLOAD_PROFILE = '/uploadProfile';

// API url
export const LOGOUT = "/logout";
export const GET_BASIC_DATA = "/getBasicData";
export const GET_SEEKERS_DATA = "/getSeekersData";
export const UPDATE_JOBSEEKERS_PROFILE = "/updateJobseekersProfile";
export const DOWNLOAD_RESUME = "/downloadresume";

// experience
export const GET_EXPERIENCE = "/getExperience";
export const STORE_EXPERIENCE = "/storeExperience";
export const DELETE_EXPERIENCE = "/deleteExperience";
export const UPDATE_EXPERIENCE = "/updateExperience";

// skill and certification profile
export const GET_SKILL_CERT = "/getSkillCert";
export const UPDATE_SKILL_CERT = "/updateSkillCert";

// setting
export const OLD_PASS_CHECK = "/oldPassCheck";
export const CHANGE_PASSWORD = "/changePassword";
export const NOTIFICATION_STATUS_CHANGE = "/notificationStatusChange";
export const DEACTIVATE_ACCOUNT = "/deactivateaccount";
export const CHANGE_VISIBLE_STATUS = "/changevisiblestatus";
